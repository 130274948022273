/* eslint-disable react/function-component-definition */
import React from 'react';

import { Header, Button } from 'semantic-ui-react';

import {
  LINK_CPTA_RDCA_DAP,
  LINK_FDA_ACKNOWLEDGMENT,
} from 'env-create-react-app';

import CPTALogoHor from '../images/CPTA_logo.png';

function toggleOverview() {
  const element = document.getElementById('flex');
  if (element.classList.contains('overview-hidden')) {
    document.getElementById('overview-content').style.marginLeft = '1rem';
  } else {
    const contentWidth = document.getElementById('overview-content').offsetWidth - 56;
    document.getElementById('overview-content').style.marginLeft = `-${contentWidth}px`;
  }
  element.classList.toggle('overview-hidden');
}

const Overview = () => (
  <div className="overview">
    <Button type="button" aria-label="Toggle Overview" className="overview_toggle toggle left" onClick={toggleOverview}><i className="arrow circle left icon" /></Button>
    <Button type="button" aria-label="Toggle Overview" className="overview_toggle toggle right" onClick={toggleOverview}><i className="arrow circle right icon" /></Button>
    <div className="content" id="overview-content">
      <Header as="h2">Overview</Header>
      <p>
        The Critical Path to Therapeutics for the Ataxias (CPTA) is a public-private partnership
        with the mission to optimize clinical trials for inherited ataxias. Through facilitating
        a collaborative and consensus-driven community of patient advocates, researchers,
        and regulators, CPTA aims to work collaboratively to develop tools and complete analyses
        to accelerate the development of new therapies for inherited ataxias.
      </p>
      <p>
        Please visit the
        {' '}
        <a href={LINK_CPTA_RDCA_DAP} target="_blank" rel="noreferrer">CPTA overview</a>
        {' '}
        to learn more.
      </p>

      <img src={CPTALogoHor} alt="Workspaces Overview" id="overviewLogo" />
      <p>
        Data contributed to CPTA is hosted on C-Path's Rare Disease Cures Accelerator
        - Data and Analytics Platform (RDCA-DAP), an FDA-funded initiative that provides
        a centralized and standardized infrastructure for data across rare and orphan diseases.
        {' '}
        <a href={LINK_FDA_ACKNOWLEDGMENT} target="_blank" rel="noreferrer"> Click here for more information.</a>
        {' '}
      </p>

      <Header as="h2">Who we are</Header>

      <p>
        Officially launched in February 2021, CPTA is a public-private partnership with the mission
        to optimize clinical trials for inherited ataxias. CPTA is a collaborative effort between
        C-Path, the Ataxia Global Initiative, the National Ataxia Foundation, Ataxia UK, the Ataxia
        Charlevoix-Saguenay Foundation and key partners in the industry and academic research
        communities, including Biogen, Biohaven Pharmaceuticals, Ionis, PTC Therapeutics, Roche,
        Servier Pharmaceuticals and VICO Therapeutics.
      </p>

      <Header as="h2">Our mission</Header>

      <p>
        Inherited ataxias are a diverse collection of genetic disorders with unique genetic causes
        and a spectrum of clinical presentation, affecting many different body systems. Although
        the pipeline of new medical products for the ataxias is expanding, there remain large gaps
        in our understanding of these diverse disorders.

      </p>
      <p>
        There is an urgent need for actionable tools to understand the natural history, progression,
        and variance in these diseases, as well as how to measure therapeutic effects in clinical
        trials.
      </p>
      <p>
        Our mission is to bring together experts from across different fields of ataxia research,
        advocacy, and medical product development to create regulatory tools and strategies that
        will catalyze research in the ataxias. In doing so, we aim to increase the efficiency
        and effectiveness of medical product development for these disorders and deliver innovative
        treatments to patients in need.
      </p>

    </div>
  </div>
);

export default Overview;
