/* eslint-disable react/function-component-definition */
import React from 'react';

const AnnouncementCount = ({ index, total }) => (
  <div className="aridhia-count">
    {index}
    {' '}
    of
    {' '}
    {total}
  </div>
);

export default AnnouncementCount;
