/* eslint-disable react/function-component-definition */
import React from 'react';
import { Message } from 'semantic-ui-react';
import MarkdownRender from './MarkdownRender';

const timeFormatter = (datetime) => new Date(datetime).toLocaleTimeString(
  [],
  { hour: '2-digit', minute: '2-digit', hour12: false },
);

const dateFormatter = (datetime) => new Date(datetime).toLocaleDateString(
  'en-gb',
  { year: 'numeric', month: 'short', day: '2-digit' },
);

const displayDates = (from, to) => {
  let dateText = '';
  if (from) {
    dateText += `${dateFormatter(from)} ${timeFormatter(from)}`;
    if (to) dateText += ' – ';
  }
  if (to) {
    dateText += `${dateFormatter(to)} ${timeFormatter(to)}`;
  }
  return dateText;
};

const AnnouncementContent = ({ announcement }) => (
  <Message.Content>
    {announcement?.headline
      && <Message.Header className="truncate">
        {announcement.headline}
        {(announcement.dateTimeFrom || announcement.dateTimeTo)
          && <span className="aridhia-date">{displayDates(announcement.dateTimeFrom, announcement.dateTimeTo)}</span>}
      </Message.Header>}
    <div className="text truncate">
      {announcement?.linkedContent
      && <MarkdownRender value={announcement.linkedContent} />}
    </div>
  </Message.Content>
);
export default AnnouncementContent;
