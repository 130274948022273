/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  Container,
  Segment,
  Breadcrumb,
  Icon,
  Form,
  Button,
  Message,
} from 'semantic-ui-react';

import axios from 'axios';

import {
  API_BASE,
  API_ENDPOINT_CONTRIBUTE_DATA,
} from 'env-create-react-app';

function ContributeData() {
  const [submitStatus, setSubmitStatus] = useState({ type: '', show: false });

  const [submitDisabled, setSubmitDisabled] = useState(false);

  function SubmitMessage() {
    setSubmitDisabled(true);
    if (submitStatus.type === 'submitting') {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            We are submitting that for you.
          </Message.Content>
        </Message>
      );
    }
    if (submitStatus.type === 'success') {
      return (
        <Message positive>
          <Message.Content>
            <Message.Header>
              Success!
            </Message.Header>
            Thank you for making the request which is now under review.
          </Message.Content>
        </Message>
      );
    }
    if (submitStatus.type === 'error') {
      return (
        <Message negative>
          <Message.Content>
            <Message.Header>
              Error!
            </Message.Header>
            Unfortunately there was an error processing your request.
          </Message.Content>
        </Message>
      );
    }
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <></>
    );
  }

  const {
    register: contribute, handleSubmit,
  } = useForm({
    defaultValues: {
      anonymizedData: 'No',
      legalRights: 'No',
    },
  });
  const onSubmit = (data) => {
    setSubmitStatus({ type: 'submitting', show: true });
    axios
      .post(
        `${API_BASE}${API_ENDPOINT_CONTRIBUTE_DATA}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setSubmitStatus({ type: 'success', show: true });
      })
      .catch((error) => {
        if (error.response) {
          setSubmitStatus({ type: 'error', show: true });
        }
      });
  };
  function ContributeDataForm() {
    return <Card fluid>
      <Card.Header className="content">
        <Icon name="exclamation circle" size="large" className="right floated white" />
        <span className="workspace-name">Contribute Data</span>
      </Card.Header>
      <Card.Content>
        <Card.Description>
          <p>
            This form can be used to apply to contribute your data. Response will be via
            email to your contact email address.
          </p>
          <p>Please allow 10 working days for a response.</p>
        </Card.Description>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label htmlFor="organization">
              Organization
              <span className="required">*</span>
              <input id="organization" type="text" placeholder="Organization" {...contribute('organization')} required />
            </label>
          </Form.Field>
          <Form.Field>
            <label htmlFor="contactName">
              Contact Name
              <span className="required">*</span>
              <input type="text" placeholder="Contact Name" {...contribute('contactName')} required />
            </label>
          </Form.Field>
          <Form.Field>
            <label htmlFor="contactPhone">
              Contact Phone
              <span className="required">*</span>
              <input type="tel" placeholder="Contact Phone" {...contribute('contactPhone')} required />
            </label>
          </Form.Field>
          <Form.Field>
            <label htmlFor="contactEmail">
              Contact Email
              <span className="required">*</span>
              <input type="email" placeholder="Contact Email" {...contribute('contactEmail')} required />
            </label>
          </Form.Field>
          <Form.Field>
            <label htmlFor="typeOfData">
              Type of data
              <span className="required">*</span>
              <select {...contribute('typeOfData')} required>
                <option value="Clinical Trial">Clinical Trial</option>
                <option value="Pre-clinical Trial">Pre-clinical Trial</option>
                <option value="Registry (patient or clinician)">Registry (patient or clinician)</option>
                <option value="Natural History Study">Natural History Study</option>
                <option value="Electronic Health Record (EHR)/Real World Data(RWD)">Electronic Health Record (EHR)/Real World Data(RWD)</option>
                <option value="Imaging">Imaging</option>
                <option value="Genomics">Genomics</option>
                <option value="Other">Other</option>
              </select>
            </label>
          </Form.Field>
          <Form.Field>
            <label htmlFor="diseaseArea">
              Disease Area
              <span className="required">*</span>
              <input type="text" placeholder="Disease area" {...contribute('diseaseArea')} required />
            </label>
          </Form.Field>
          <Form.Field id="legalRightsRadioGroup">
            <label htmlFor="legalRightsRadioGroup">
              Do you have all legal rights necessary to share this data?
              <span className="required">*</span>
              <Form.Field>
                <label htmlFor="legalRightsYes">
                  <input {...contribute('legalRights')} type="radio" value="Yes" />
                  Yes
                </label>
              </Form.Field>
              <Form.Field>
                <label htmlFor="legalRightsNo">
                  <input {...contribute('legalRights')} type="radio" value="No" />
                  No
                </label>
              </Form.Field>
            </label>
          </Form.Field>
          <Form.Field id="anonymizedDataRadioGroup">
            <label htmlFor="anonymizedDataRadioGroup" className="required field">
              Are the data anonymized/deidentified of all personally identifiable information?
              <span className="required">*</span>
              <Form.Field>
                <label htmlFor="anonymizedDataYes" className="RadioGroupLabel">
                  <input {...contribute('anonymizedData')} type="radio" value="Yes" className="ui radio checkbox" />
                  Yes
                </label>
              </Form.Field>
              <Form.Field>
                <label htmlFor="anonymizedDataNo">
                  <input {...contribute('anonymizedData')} type="radio" value="No" className="ui radio checkbox" />
                  No
                </label>
              </Form.Field>
            </label>
          </Form.Field>
          <Form.Field>{submitStatus.show ? SubmitMessage() : ''}</Form.Field>
          <div>
            <Button disabled={submitDisabled}>
              Submit
            </Button>
          </div>
        </Form>
      </Card.Content>
    </Card>;
  }

  return (
    <div className="portal page extra content">
      <Container>
        <Segment className="portal-elements">
          <Breadcrumb>
            <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Contribute Data</Breadcrumb.Section>
          </Breadcrumb>
        </Segment>
        <ContributeDataForm />
      </Container>
    </div>
  );
}

export default ContributeData;
