/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Segment,
  Breadcrumb,
  Card,
  Form,
  Button,
  Message,
  Icon,
  Ref,
  Divider,
} from 'semantic-ui-react';
import axios from 'axios';

import {
  API_BASE,
  API_ENDPOINT_REQUEST_WORKSPACE,
  LINK_ARIDHIA_CONTACT_SD,
  LINK_CPTA_TERMS_OF_USE,
} from 'env-create-react-app';

import { msalObj } from '../auth/signin';

const RequestWorkspace = ({ loggedIn, signIn }) => {
  const valuesOrig = {
    vm_linux: false,
    vm_windows: false,
    acceptToC: false,
    sector: '',
    data_access_intent: '',

  };

  const [values, setValues] = useState(valuesOrig);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const formRef = useRef(null);

  const [message, setMessage] = useState({
    showMessage: false,
    header: '',
    message: '',
    isLoading: '',
    isError: false,
    isSuccess: false,
  });

  const onChange = (event, result) => {
    const { name, value } = result || event.target;
    setValues({ ...values, [name]: value });
  };

  const onChangeCheckbox = (event, result) => {
    const { name, checked } = result || event.target;
    setValues({ ...values, [name]: checked });
  };

  const hideMessage = (clearFields = true) => {
    setMessage({ showMessage: false });
    if (clearFields) {
      setValues(valuesOrig);
      formRef.current.reset();
    }
    setSubmitDisabled(false);
  };

  const handleSuccessMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Message Sent',
      message:
        'Thank you for making the request which is now under review. You will be provided an invitation to your workspace on approval.',
      isLoading: false,
      isError: false,
      isToCError: false,
      isSuccess: true,
    });
  };

  const handlefailIdTokenMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Failed to retrieve ID Token',
      message: 'There was an error processing your request. (Authentication error).',
      isLoading: false,
      isError: false,
      isToCError: false,
      isSuccess: true,
    });
    setSubmitDisabled(false);
  };

  const handleFailUnauthorizedMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Unauthorized',
      message: 'Your token was unauthorized. Please try to log out and log back in again.',
      isLoading: false,
      isError: true,
      isToCError: false,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const handleGenericErrorMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Error',
      message: 'Unfortunately there was an error processing your request.',
      isLoading: false,
      isError: true,
      isToCError: false,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const handleToCErrorMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Please accept the Terms and Conditions',
      message: 'In order to submit this request you must read and accept the terms and conditions.',
      isLoading: false,
      isError: false,
      isToCError: true,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const handleToDataScienceVMMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Please select if you wish to apply the Data Science VM Template',
      message: 'In order to submit this request you must you must fix the above error.',
      isLoading: false,
      isError: false,
      isToCError: true,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };
  const handleSectorMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Please select the sector of you institution or organization',
      message: 'In order to submit this request you must you must fix the above error.',
      isLoading: false,
      isError: false,
      isToCError: true,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };
  const displayMessageContent = () => {
    if (message.isLoading) {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>{message.header}</Message.Header>
            <p>{message.message}</p>
          </Message.Content>
        </Message>
      );
    }

    if (message.isError) {
      return (
        <Message negative onDismiss={() => hideMessage(false)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
          <p>
            If you keep seeing this message, please
            {' '}
            <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
              contact the Service Desk
            </a>
            .
          </p>
        </Message>
      );
    }

    if (message.isToCError) {
      return (
        <Message negative onDismiss={() => hideMessage(false)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
        </Message>
      );
    }

    if (message.isSuccess) {
      return (
        <Message positive onDismiss={() => hideMessage(true)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
        </Message>
      );
    }

    return (
      <Message onDismiss={() => hideMessage(false)}>
        <Message.Header>{message.header}</Message.Header>
        <p>{message.message}</p>
      </Message>
    );
  };

  const onSubmit = (event) => {
    setSubmitDisabled(true);

    if (!values.apply_data_science_template && (values.vm_linux || values.vm_windows)) {
      handleToDataScienceVMMessage();
      return;
    }

    if (!values.apply_data_science_template && !values.vm_linux && !values.vm_windows) {
      values.apply_data_science_template = 'No VM was selected.';
    }

    if (!values.acceptToC) {
      handleToCErrorMessage();
      return;
    }
    // html dropdown boxes don't support a required property so have to do a manual check here
    if (values.sector === '') {
      handleSectorMessage();
      return;
    }

    setMessage({
      showMessage: true,
      header: 'Just one second',
      message: 'We are submitting your form.',
      isLoading: true,
      isError: false,
      isSuccess: false,
    });

    const account = msalObj.getAccount();
    if (typeof account.idToken === 'undefined' || account.idToken == null) {
      console.error('Failed to obtain idToken');
      handlefailIdTokenMessage();
    } else {
      // Information on the logged in account
      const givenName = account.idToken.given_name;
      const familyName = account.idToken.family_name;
      const email = account.idToken.emails && account.idToken.emails.length > 0
        ? account.idToken.emails[0]
        : 'Not Available';
      const userid = account.idToken.oid ? account.idToken.oid : 'Not Available';

      let data = values;
      // if the data access is data only then clear all the additional questions
      // Checking here so setState isn't called.  If someone accidentally changes the
      // selection we don't want to lose all their data from the form - that would be annoying
      if (values.data_access_intent === 'Workspace for data download only') {
        data.vm_linux = false;
        data.vm_windows = false;
        data.acceptToC = false;
        data.additional_users = 'N/A';
        data.other_requirements = 'N/A';
        data.apply_data_science_template = 'N/A';
      }

      data.timestamp = `${new Date().toISOString()}`;
      data.source_url = window.location.href;
      data.userid = userid;
      data.email = email;
      data.full_name = `${givenName} ${familyName}`;
      data = JSON.stringify(data);
      axios
        .post(
          `${API_BASE}${API_ENDPOINT_REQUEST_WORKSPACE}`,

          data,
          {
            headers: {
              Authorization: `Bearer ${window.sessionStorage.getItem('msal.idtoken')}`,
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => {
          handleSuccessMessage();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response.status === 401) {
              handleFailUnauthorizedMessage();
            } else {
              handleGenericErrorMessage();
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            handleGenericErrorMessage();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            handleGenericErrorMessage();
          }
        });
      event.preventDefault();
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const LoginRequest = () => (
    <Card fluid>
      <Card.Header className="content">
        <Icon name="exclamation circle" size="large" className="right floated white" />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={signIn} role="button" className="workspace-name">
          Sign In To Access Request a Workspace Form
        </a>
      </Card.Header>
      <Card.Content>
        <Card.Description>
          <p>
            This form requires a valid login to work.
            {' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={signIn} role="button">
              Please sign in
            </a>
            {' '}
            to access the Request a Workspace Form.
          </p>
          <br />
          <p>
            If you are having trouble signing in or require assistance, please
            {' '}
            <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
              contact the Service Desk
            </a>
            .
          </p>
        </Card.Description>
      </Card.Content>
    </Card>
  );
  const sectorOptions = [
    {
      key: 'academia_or_research',
      text: 'Academia/Research',
      value: 'Academia/Research',
    },
    {
      key: 'pharma_or_industry',
      text: 'Pharma/Industry',
      value: 'Pharma/Industry',
    },
    {
      key: 'clinician',
      text: 'Clinician',
      value: 'Clinician',
    },
    {
      key: 'non_profit',
      text: 'Non-profit',
      value: 'Non-profit',
    },
    {
      key: 'government',
      text: 'Government',
      value: 'Government',
    },
    {
      key: 'patient_or_caregiver',
      text: 'Patient/Caregiver',
      value: 'Patient/Caregiver',
    },
    {
      key: 'patient_advisory_group',
      text: 'Patient Advocacy Group',
      value: 'Patient Advocacy Group',
    },
    {
      key: 'other',
      text: 'Other',
      value: 'Other',
    },

  ];

  const RequestWorkspaceForm = () => (
    <Card fluid>
      <Card.Header className="content">
        <Icon name="exclamation circle" size="large" className="right floated white" />
        <span className="workspace-name">Request a Workspace</span>
      </Card.Header>
      <Card.Content className="square-corners">
        <Card.Description>
          <p>
            This form can be used to request a workspace. Response will be via email to your
            registered user account.
          </p>
          <p>Please allow 3-5 business days for your request to be processed.</p>
        </Card.Description>
      </Card.Content>
      <Card.Content>
        <Ref innerRef={formRef}>
          <Form id="form_request_workspace" onSubmit={onSubmit}>
            <Form.Field required>
              <Form.Input
                label="Institute or Organization"
                name="institute_name"
                placeholder="Institute or Organization"
                onChange={onChange}
                required
              />
            </Form.Field>
            <Form.Field required>
              <Form.Select
                required
                label="Sector"
                name="sector"
                placeholder="Please select the sector of your institution or organization"
                onChange={onChange}
                options={sectorOptions}
                selection
              />
            </Form.Field>
            <Form.Field required>
              <Form.Input
                label="Project or Research Name"
                name="project_name"
                placeholder="Project or Research Name"
                onChange={onChange}
                required
              />
            </Form.Field>
            <Form.Field required>
              <Form.TextArea
                label="Synopsis of Proposed Research"
                name="project_description"
                placeholder="Please describe the purpose, goals, and if you have any intentions for publication."
                onChange={onChange}
                rows="5"
                required
              />
            </Form.Field>
            <Form.Input
              label="What do you intend for data access"
              required
              className="form-input-stack-items"
              input={
                <>
                  <Form.Radio
                    label="Workspace for data download only"
                    name="data_access_intent"
                    value="Workspace for data download only"
                    checked={values.data_access_intent === 'Workspace for data download only'}
                    onChange={onChange}
                  />
                  <Form.Radio
                    label="NEW workspace for analysis."
                    name="data_access_intent"
                    value="NEW workspace for analysis"
                    checked={values.data_access_intent === 'NEW workspace for analysis'}
                    onChange={onChange}
                  />
                </>
                    }
            />
            <div className={values.data_access_intent === 'NEW workspace for analysis' ? null : 'hidden'}>
              <Divider />
              <Form.Field>
                <Form.TextArea
                  label="Additional Users"
                  name="additional_users"
                  placeholder="Please provide email addresses of other users who should be invited to the workspace."
                  onChange={onChange}
                  rows="3"
                />
              </Form.Field>
              <Divider />
              <Form.Group className="grouped">
                <h5>Do you require a virtual machine?</h5>
                <Form.Field>
                  <Form.Checkbox
                    id="vm_windows"
                    name="vm_windows"
                    label="Windows Virtual Machine"
                    onClick={onChangeCheckbox}
                    checked={values.vm_windows}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Checkbox
                    id="vm_linux"
                    name="vm_linux"
                    label="Linux Virtual Machine"
                    onClick={onChangeCheckbox}
                    checked={values.vm_linux}
                  />
                </Form.Field>
              </Form.Group>

              {values.vm_linux || values.vm_windows ? (
                <Form.Group className="grouped">
                  <Form.Field // form input allows for error tag to be grouped
                    id="form-input-datasciencevm" // allows hook to scroll to in case of error
                    style={{ scrollMarginTop: '5rem' }}
                  >

                    <label htmlFor="vm_radios">
                      <p>
                        Do you a require a Data Science template to be applied to your Virtual
                        Machine? The Data Science template includes the most common tools used in
                        Data Science Workflows i.e.
                        <span className="required">*</span>
                      </p>

                      <ul>
                        <li>
                          <a href="https://jupyter.org/" target="_blank" rel="noreferrer">
                            Jupyter Notebook
                          </a>
                        </li>
                        <li>
                          <a href="https://www.rstudio.com/" target="_blank" rel="noreferrer">
                            RStudio
                          </a>
                        </li>
                        <li>
                          <a href="https://code.visualstudio.com/" target="_blank" rel="noreferrer">
                            Visual Studio Code
                          </a>
                        </li>
                        <li>
                          <a href="https://julialang.org/" target="_blank" rel="noreferrer">
                            Julia
                          </a>
                        </li>
                        <li>
                          <a href="https://cran.r-project.org/" target="_blank" rel="noreferrer">
                            CRAN-R
                          </a>
                        </li>
                      </ul>
                      <p>
                        For more information please reference our Knowledge Base article
                        {' '}
                        <a href="https://knowledgebase.aridhia.io/article/using-a-data-science-virtual-machine/" target="_blank" rel="noreferrer">Using a Data Science Virtual Machine – Aridhia DRE Workspaces Knowledge Base</a>
                      </p>
                      <Form.Input
                        required
                        className="form-input-stack-items"
                        name="vm_radios"
                        input={
                          <>
                            <Form.Radio
                              label="Yes, apply the Data Science template to the virtual machine."
                              name="apply_data_science_template"
                              value="Yes"
                              checked={values.apply_data_science_template === 'Yes'}
                              onChange={onChange}
                            />
                            <Form.Radio
                              label="No, I will install my own tools on the virtual machine."
                              name="apply_data_science_template"
                              value="No"
                              checked={values.apply_data_science_template === 'No'}
                              onChange={onChange}
                            />
                          </>
                    }
                      />
                    </label>
                  </Form.Field>
                </Form.Group>
              ) : (
                ''
              )}
              <Form.Field>
                <Form.TextArea
                  label="Any other requirements?"
                  name="other_requirements"
                  placeholder="Please provide any additional details to help us set up your workspace."
                  onChange={onChange}
                  rows="3"
                />
              </Form.Field>
              <Divider />
            </div>
            <p>
              Before submitting the request, please make sure you have read and agreed to the
              {' '}
              <a href={LINK_CPTA_TERMS_OF_USE} target="_blank" rel="noreferrer">
                Terms and Conditions
              </a>
              {' '}
              and have visited both
              {' '}
              <a
                href="https://workspaces.westeurope.dap.c-path.org/"
                target="_blank"
                rel="noreferrer"
              >
                Workspaces
              </a>
              {' '}
              and
              {' '}
              <a href="https://fair.dap.c-path.org/" target="_blank" rel="noreferrer">
                FAIR Data Services
              </a>
              .
            </p>

            <Form.Field>
              <Form.Checkbox
                id="acceptToC"
                name="acceptToC"
                label="Agree to Terms and Conditions"
                onClick={onChangeCheckbox}
                checked={values.acceptToC}
              />
            </Form.Field>
            <Form.Field>{message.showMessage ? displayMessageContent() : ''}</Form.Field>
            <div>
              <Button id="form_request_workspace_submit" type="submit" className="form" disabled={submitDisabled}>
                Submit
              </Button>
            </div>
          </Form>
        </Ref>
      </Card.Content>
    </Card>
  );

  const FormContents = () => {
    if (loggedIn) {
      return RequestWorkspaceForm();
    }
    return LoginRequest();
  };

  return (
    <div className="portal page extra content">
      <Container>
        <Segment className="portal-elements">
          <Breadcrumb>
            <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Request a Workspace</Breadcrumb.Section>
          </Breadcrumb>
        </Segment>
        {FormContents()}
      </Container>
    </div>
  );
};

RequestWorkspace.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  signIn: PropTypes.func.isRequired,
};

export default RequestWorkspace;
