/* eslint-disable react/function-component-definition */
import React from 'react';
import {
  Container, Breadcrumb, Segment, Card, Header, Icon,
} from 'semantic-ui-react';

const Security = () => (
  <div className="portal page extra content">
    <Container>
      <Breadcrumb>
        <Breadcrumb.Section link href="/">Home</Breadcrumb.Section>
        <Breadcrumb.Divider icon="right angle" />
        <Breadcrumb.Section active>Data Security</Breadcrumb.Section>
      </Breadcrumb>

      <Segment className="portal-elements">
        <Card fluid>
          <Card.Header className="content">
            <Icon name="shield alternate" size="large" className="right floated blue" />
            <span className="workspace-name">Data Security</span>
          </Card.Header>
          <Card.Content>
            <Card.Description>
              <p>
                Keeping adequate protections on sensitive data is of utmost importance to
                ensure the safety, and the trust, of study participants. Thus, in establishing
                this system for sharing and accessing data, particular attention is placed on
                governance and security models. Governance here means the constraints on how
                the data is ingressed, stored, egressed, tools, and the technical, including
                cybersecurity and breach procedures.
              </p>
              <p>
                Currently, workspaces require approval to invite additional participants,
                download data from the workspaces, and accessing sites/installing tools
                outside of those on the allow list mentioned below. In future updates,
                more advanced techniques will be developed so that only workspaces with
                access to the most restrictive data will require these precautions.
              </p>
            </Card.Description>
          </Card.Content>
        </Card>

        <Card fluid>
          <Card.Header className="content">
            <Icon name="arrows alternate horizontal" size="large" className="right floated blue" />
            <span className="workspace-name">Network controls</span>
          </Card.Header>
          <Card.Content>
            <Card.Description>
              <p>
                <b>Inbound</b>
                {' '}
                connections to the Workbench are controlled by multi-factor authentication.
                This means that you must be a logged in user verified with a second device
                every time you use the service. There are limited ways you can connect to the
                Workspace - all traffic is through HTTPS and usually through a browser. Due
                to limitations in how data transfers can be controlled, we are unable to support
                direct
                {' '}
                <i>ssh</i>
                {' '}
                connections to Workspaces. For similar reasons, it is not possible to provide
                direct access to
                {' '}
                <i>github.com</i>
                {' '}
                (a private gitlab service can be provided).
              </p>
              <p>
                <b>Outbound</b>
                {' '}
                connections are regulated through a proxy and an
                {' '}
                <b>allow</b>
                {' '}
                list. This means that users in a Workspace connecting to Internet services can only
                connect to systems explicitly on the allow list. Currently it is not possible to
                regulate connections per workspace, so approvals for inclusion in the allow list
                have to consider the needs of all Workspace users.
              </p>
              <p>
                Please contact the
                {' '}
                <a href="mailto:servicedesk@aridhia.com">Service Desk</a>
                {' '}
                if you need to request access to a particular site that is not on the list.
              </p>
              <p>
                <b>Note</b>
                {' '}
                Workspace users can always upload software and data into their private Workspace.
              </p>
            </Card.Description>
          </Card.Content>
        </Card>

        <Header as="h2">Allow List</Header>

        <table className="ui celled unstackable striped table top">
          <thead>
            <tr>
              <th>Purpose</th>
              <th>Domains allowed</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                R Package management
              </td>
              <td>
                <ul>
                  <li>r.aridhia.net</li>
                  <li>cloud.r-project.org</li>
                  <li>.rstudio.org</li>
                  <li>rstudio.com</li>
                  <li>www.stats.bris.ac.uk</li>
                  <li>cran.ma.imperial.ac.uk</li>
                  <li>cran.cnr.berkeley.edu</li>
                  <li>mran.microsoft.com</li>
                  <li>ftp.osuosl.org</li>
                  <li>bioconductor.org</li>
                </ul>
              </td>
              <td>
                Allows users to install a wide range of R-based statistical, machine learning and
                visualisation packages from community code repositories.
              </td>
            </tr>
            <tr>
              <td>
                Python Package management
              </td>
              <td>
                <ul>
                  <li>pypi.python.org</li>
                  <li>pypi.org</li>
                  <li>files.pythonhosted.org</li>
                  <li>anaconda.org</li>
                  <li>repo.anaconda.com</li>
                </ul>
              </td>
              <td>
                Allows users to install a wide range of Python-based statistical, machine learning
                and visualisation packages from community code repositories
              </td>
            </tr>
            <tr>
              <td>
                Ubuntu Linux package management
              </td>
              <td>
                <ul>
                  <li>ftp.heanet.ie</li>
                  <li>nl.archive.ubuntu.com</li>
                  <li>file.core.windows.net</li>
                  <li>azure.archive.ubuntu.com</li>
                  <li>archive.ubuntu.com</li>
                  <li>security.ubuntu.com</li>
                  <li>ppa.launchpad.net</li>
                </ul>
              </td>
              <td>
                Allows users to install standard software packages and dependencies for statistical
                analysis, as well as apply security and operating system updates to the Ubuntu Linux
                virtual machines in Workspaces.
              </td>
            </tr>
            <tr>
              <td>
                Windows patching
              </td>
              <td>
                <ul>
                  <li>packages.microsoft.com</li>
                  <li>file.core.windows.net</li>
                </ul>
              </td>
              <td>
                Allows users to maintain the operating system on Windows virtual machines
                in Workspaces.
              </td>
            </tr>
            <tr>
              <td>
                NVIDIA GPU support
              </td>
              <td>
                <ul>
                  <li>developer.download.nvidia.com</li>
                </ul>
              </td>
              <td>
                Allows users to download and install essential libraries for running machine
                learning frameworks (like Tensorflow) on NVIDIA GPU hardware in Virtual
                Machines in the Workspace.
              </td>
            </tr>
          </tbody>
        </table>
      </Segment>
    </Container>
  </div>
);

export default Security;
